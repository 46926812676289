import axios from 'axios';


export function getShoutoutByLocationId(locationId) {
    return axios.get(`/api/v1/shoutouts/location/${locationId}`, { timeout: 5000 })
    .then(response => response.data.data)
    .then((data) => {
        if (!data) {
            throw new Error('cant find shoutout');
        }
        return data;
    }).catch((err) => {
        console.log(err)
    })
};


export function getShoutoutById(shoutoutId) {
    return axios.get(`/api/v1/shoutouts/default`, { timeout: 5000 })
    .then(response => response.data.data)
    .then((data) => {
        if (!data) {
            throw new Error('cant find shoutout');
        }
        return data;
    }).catch((err) => {
        console.log(err)
    })
};


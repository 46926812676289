import axios from "axios";
import { isBase64 } from "../utils/Utils";

export function getContent(token) {
  if (token) {
    return axios
      .get(`/api/v1/content/${token}`, { timeout: 5000 })
      .then((response) => response.data.data)
      .then(async (data) => {
        let content;
        if (data) {
          if (data.content) {
            if (isBase64(data.content)) {
              content = JSON.parse(window.atob(data.content));
            } else {
              content = JSON.parse(data.content);
            }
          } else {
            content = await getDefaultContent();
          }
        } else {
          throw new Error(`/api/v1/content/${token} - no data in response`);
        }

        return {
          ...content,
          mobileq: data.mobileq.uploadUrl,
          fence: data.mobileq.fence,
          analyticsId: data.analyticsId,
          locationId: data.locationId,
          locationName: data.locationName,
          configuration: data.configuration,
        };
      })
      .catch(() => {
        return getDefaultContent();
      });
  }

  return getDefaultContent();
}

export function getContentByLocationShortId(shortId) {
  if (!shortId) {
    return getDefaultContent();
  }

  const apiUrl = `/api/v1/content/code/${shortId}`;

  return requestLocationContent(apiUrl);
}

async function requestLocationContent(apiUrl) {
  return axios
    .get(apiUrl, { timeout: 5000 })
    .then((response) => response.data.data)
    .then(async (data) => {
      let content;
      if (data) {
        if (data.content) {
          if (isBase64(data.content)) {
            content = JSON.parse(window.atob(data.content));
          } else {
            content = JSON.parse(data.content);
          }
        } else {
          content = await getDefaultContent();
        }
      } else {
        throw new Error(`${apiUrl} - no data in response`);
      }

      return {
        ...data,
        ...content,
        mobileq: data.mobileq.uploadUrl,
        fence: data.mobileq.fence,
      };
    })
    .catch((err) => {
      return getDefaultContent();
    });
}

export function getContentForIFrame(locationId, templateId) {
  if (locationId && templateId) {
    return axios
      .get(`/api/v1/content/iframe/${locationId}/${templateId}`)
      .then((response) => response.data.data)
      .then(async (data) => {
        let content;
        if (data) {
          content = JSON.parse(data.content);
        } else {
          throw new Error(`/api/v1/content/iframe/${locationId}/${templateId} - no data in response`);
        }
        return {
          ...content,
          analyticsId: data.analyticsId,
          locationId: data.locationId,
          locationName: data.locationName,
          configuration: data.configuration,
          mobileq: data.mobileq,
          shortId: data.shortId
        };
      })
      .catch((err) => {
        return getDefaultContent();
      });
  }

  return getDefaultContent();
}

export function getDefaultContent() {
  return axios
    .get(`data/default.b64`)
    .then((response) => response.data)
    .then((data) => {
      const a64 = window.atob(data);
      return JSON.parse(a64);
    });
}

export function getDefaultContentWithConfiguration() {
  return axios
    .get(`/api/v1/content/default`)
    .then((response) => response.data.data)
    .then((data) => {
      const { configuration } = data;
      const content = JSON.parse(window.atob(data.content));

      return {
        ...content,
        configuration,
      };
    });
}

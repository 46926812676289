import axios from 'axios';

export let getSignUrl = (locationId) => {
    return axios.get(`/api/v1/getSignedUrl/${locationId}`, { timeout: 10000 })
        .then(response => response.data)
        .then((data) => {
            if (!data) {
                throw new Error('cant find content');
            }
            return data;
        }).catch((err) => {
            throw err;
        })
}

export let getPreviewUrl = (brand) => {
    return axios.get(`/api/v1/getBrandPreviewUrl/${brand}`, { timeout: 10000 })
        .then(response => response.data)
        .then((data) => {
            if (!data) {
                throw new Error('cant find content');
            }
            return data;
        }).catch((err) => {
            throw err;
        })
}

export let pushUrlToQ = ({url, locationId, fence, rippleId, isMacaroon}) => {
    return axios.post(`/api/v1/pushUrl/${locationId}`, { rippleUri: url , fence, rippleId, isMacaroon}, { timeout: 10000 })
        .then(response => response.data)
        .then((data) => {
            if (!data) {
                throw new Error('cant find content');
            }
            return data;
        }).catch((err) => {
            throw err;
        })
}